<template>
  <div class="progress">
    <h2 class="progress__title">Progress Bar</h2>
    <div class="progress__wrap">
      <h3 class="progress__subtitle">Progress Bar Default</h3>
      <ProgressBar :percent="0" />
    </div>
    <div class="progress__wrap">
      <h3 class="progress__subtitle">progress Props</h3>
      <ul class="progress__list">
        <li class="progress__item"><span>percent</span> - процент заполнения прогресс бара</li>
        <li class="progress__item"><span>showNumber</span> - показать/скрыть цифру процента</li>
      </ul>
    </div>
    <div class="progress__wrap">
      <h3 class="progress__subtitle">Progress Bar Fill 30% with Percent Number</h3>
      <ProgressBar :percent="30" />
    </div>
    <div class="progress__wrap">
      <h3 class="progress__subtitle">Progress Bar Fill 100% with Percent Number</h3>
      <ProgressBar :percent="100" />
    </div>
    <div class="progress__wrap">
      <h3 class="progress__subtitle">Progress Bar Fill 30% without Percent Number</h3>
      <ProgressBar :percent="30" :showNumber="false" />
    </div>
    <div class="progress__wrap">
      <h3 class="progress__subtitle">Progress Bar Fill 100% without Percent Number</h3>
      <ProgressBar :percent="100" :showNumber="false" />
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/modules/UiKit/components/ProgressBar/ProgressBar";
export default {
  name: "ProgressBarPage",
  mixins: [],
  props: [],
  components: { ProgressBar },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.progress {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;

    &_textarea {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
  }

  &__content {
    margin: 24px 0 48px;
  }

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }
}
</style>
